*{
    color: #065a5b;
}

.didacticBlock{
    background-color: rgba(194, 192, 166, 0.6);
    text-decoration: none;
    padding: 1px;
}
.didacticBlock .widthCourse1{
    width:150px;
}
.didacticBlock table th{
    font-weight: bold;
    text-align: center;
    vertical-align: middle; 
    border: 1px solid black; 
    border-collapse: collapse;
}
.didacticBlock table td{
    border: 1px solid black; 
    border-collapse: collapse;
    padding: 5px;
}

/* Code block */

.CodeBlockClass {
    position: relative;
}

.CopyButtonClass {
    position: absolute;
    top: 4px;
    right: 10px;
    cursor: pointer;
    background-color: black;
}
