body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  background-color: rgba(194, 192, 166, 0.6);
  padding: 20px;
  text-align: center;
  opacity: 66;
  color: #024554;
  position: relative;
}

.header h1 {
  font-size: 45px;
}
h1 {
  margin: 0;
}
main {
  align-items: center;
}
.mainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 25px auto;
  border-radius: 10px;
  background-color: rgba(194, 192, 166, 1);
  opacity: 66;
}
.mainSection img {
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
  max-width: 100%;
}
.backToMain {
  background-color: #065a5b;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 10px;
  cursor: pointer;
  width: 250px;
  position: relative;
  margin-left: auto;
}
.backToMain a {
  text-decoration: none;
  color: white;
}
.backToMain a:hover {
  color: black;
}
.description {
  margin: 0 20px;
  text-align: justify;
  color: #024554;
  font-size: 16px;
}

.media a {
  color: #024554;
}
.media a:hover {
  opacity: 0.4;
}

.additionalSection {
  margin-top: 50px;
  padding: 40px 20px;
  text-align: center;

  color: #024554;
}

.blocks {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 20px;
}

.block {
  text-align: center;
}
.icon {
  background-color: rgba(194, 192, 166, 1);
  color: rgba(59, 64, 26, 1);

  margin: auto;
}

.block img {
  max-width: 100%;
  height: 60%;
}

.footer {
  background-color: rgba(194, 192, 166, 0.6);
  color: #fff;
  text-align: center;
  padding: 20px;
}
.blocks a {
  text-decoration: none;
  color: inherit;
}

.blocks a:hover {
  opacity: 0.8;
}

@media (min-width: 600px) {
  .mainSection {
    flex-direction: row;
  }
  .mainSection img {
    /* flex: 1; */
    margin-right: 20px;
    max-width: 50%;
  }
  .description {
    flex: 2;
    margin-top: 0;
  }
  .blocks {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .blocks {
    grid-template-columns: repeat(4, 1fr);
  }
}
