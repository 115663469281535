.header{
    background-color: rgba(194, 192, 166, 0.6);
    color: white;
}
.header .title1 a{
    color: rgba(6, 90, 91, 1);
}
.header a{
    color:white;
}
.menu{
    background-color: rgba(6, 90, 91, 1);
    
}
.profile{
    background-color: white;
    color: rgba(6, 90, 91, 1);
    height: 60%;
    bottom: 0;
}