.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 80px;
  background: white;
  padding-bottom: 30px;
  width: 600px;
}
.container p {
  color: #065a5b;
  text-align: center;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 30px;
}
.text {
  color: #065a5b;
  font-size: 48px;
  font-weight: 700;
}
.underline {
  width: 61px;
  height: 6px;
  background: #065a5b;
  border-radius: 9px;
}
.inputs {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 480px;
  height: 80px;
  background: rgba(194, 192, 166, 0.6);
  border-radius: 6px;
}
.input img {
  margin: 0px 30px;
}
.input input {
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 19px;
}
.forgotPassword {
  padding-left: 62px;
  margin-top: 5px;
  color: rgba(194, 192, 166, 1);
  font-size: 18px;
}
.forgotPassword span {
  color: #065a5b;
  cursor: pointer;
}
.submitContainer {
  display: flex;
  gap: 30px;
  margin: 20px auto auto auto;
}
.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(6, 90, 91, 0.7);
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
  padding: 10px 40px;
}
.gray {
  background-color: rgba(194, 192, 166, 0.6);
  color: black;
}

.submitGuest {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(6, 90, 91, 0.7);
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
  padding: 10px 40px;
  color: white;
  text-decoration: none;
}
.container a {
  text-decoration: none;
}
